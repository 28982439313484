import type { ExperimentClient } from '@amplitude/experiment-js-client'
import { runAsyncWithRetries } from '../../utils/promises'
import {
  defaultFeatureVariants,
  type Feature,
  type FeatureToVariant,
  type FeatureVariants,
} from './Experiments.consts'
import storageManager from '../StorageManager'
import { Subject } from 'rxjs'
import { isClient, isServer } from 'utils/runtimeUtils'
import { useExperimentClient } from './ExperimentContext'
import { useState } from 'react'

const FORCED_VARIANTS_STORAGE_KEY = 'forcedVariants'
const NO_CLIENT_ERROR = 'Attempted to access experiments before client was set'

const onExperimentFetchSubject = new Subject()

export class ExperimentManager {
  private experimentClient: ExperimentClient | undefined

  private forcedVariants: Partial<FeatureToVariant> = isClient()
    ? storageManager.get(FORCED_VARIANTS_STORAGE_KEY) || {}
    : {}

  constructor(client?: ExperimentClient) {
    this.experimentClient = client
  }

  updateClient(client: ExperimentClient) {
    this.experimentClient = client
  }

  removeForcedVariantsIfNeeded() {
    if (isClient() && window.KEYS.forcedCountry) {
      storageManager.remove(FORCED_VARIANTS_STORAGE_KEY)
    }
  }

  private assertClient() {
    if (!this.experimentClient) {
      throw new Error(NO_CLIENT_ERROR)
    }

    return this.experimentClient
  }

  async fetch<F extends Feature>(userId: string, features?: F[]) {
    const client = this.assertClient()

    await runAsyncWithRetries({
      opName: 'fetching experiments',
      op: () =>
        client.fetch(
          { user_id: userId },
          features && features.length > 0 ? { flagKeys: features } : undefined
        ),
      logMetadata: {
        fetchUserId: userId,
      },
    })
    onExperimentFetchSubject.next()
  }

  subscribeToExperimentFetch(callback: () => void) {
    return onExperimentFetchSubject.subscribe(callback)
  }

  getVariantKey<F extends Feature>(featureName: F): FeatureVariants[F] {
    const client = this.assertClient()

    const forcedVariant = this.forcedVariants[featureName]
    if (forcedVariant) {
      return forcedVariant
    }

    return (
      (client.variant(featureName).key as FeatureVariants[F]) ||
      defaultFeatureVariants[featureName] ||
      'off'
    )
  }

  getVariantPayload<F extends Feature>(featureName: F) {
    const client = this.assertClient()

    return client.variant(featureName).payload
  }

  isEnabled(featureName: Feature): boolean {
    const variant = this.getVariantKey(featureName)
    return variant.includes('treatment') || variant === 'on'
  }

  forceVariant<F extends Feature>(feature: F, variant: FeatureVariants[F]) {
    this.forcedVariants[feature] = variant
    storageManager.set(FORCED_VARIANTS_STORAGE_KEY, this.forcedVariants)
  }

  getAllFeatures() {
    const client = this.assertClient()

    return Object.keys(client.all())
  }

  getUserProperties() {
    const client = this.assertClient()

    const userProperties: Record<string, string | undefined> = {}
    Object.entries(client.all()).map(([feature, variant]) => {
      userProperties[`[Feature] ${feature}`] = variant.value
    })
    return userProperties
  }
}

export const experimentManager = new ExperimentManager()

export function useExperimentManager() {
  const client = useExperimentClient()
  const [manager] = useState(() => {
    if (isServer()) {
      return new ExperimentManager(client)
    }

    return experimentManager
  })

  return manager
}
